// I set this file's format as .tsx in order to be able to see the colors directly in vscode

export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 225;
export const TOP_NAV_HEIGHT = 55;

// studio
export const STUDIO_SIDE_NAV = 340;
export const STUDIO_BOTTOM_NAV_MAIN = 280;
export const STUDIO_BOTTOM_NAV_SUBMENU = 360;
export const STUDIO_SUBMENU_DONE_MOBILE = 80;
export const STUDIO_HEADER_NAV = 82;
export const STUDIO_FOOTER_NAV = 120;
//

export const SIDE_NAV_COLLAPSED_WIDTH = 0;
export const MOBILE_NAV_WIDTH = 350;
export const SIDE_NAV_LIGHT = "SIDE_NAV_LIGHT";
export const SIDE_NAV_DARK = "SIDE_NAV_DARK";
export const NAV_TYPE_SIDE = "SIDE";
export const NAV_TYPE_TOP = "TOP";
export const DIR_LTR = "ltr";
export const DIR_RTL = "rtl";

export const FONT_FAMILY = `Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
Noto Color Emoji`;
export const FONT_FAMILY_CODE = `SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace`;
export const PRIMARY = "#ffae8a";
export const LIGHT_PRIMARY = "#f9d1c0";
export const LIGHTER_PRIMARY = "#fff7f0";
export const TEXT_DARK_GRAY = "#1A3353";
export const TEXT_GRAY = "#455560";
export const TEXT_GRAY_LIGHT = "#72849A";
export const TEXT_SUCCESS = "#04d182";
export const LIGHT_GRAY = "#e6ebf1";
export const WHITE = "#fff";

export const LINE_HEIGHT = 2.25;
export const BORDER_RADIUS = 10;
export const AVATAR_CONTAINER_SIZE = 42;
export const CONTROL_HEIGHT = 45;
export const CONTROL_HEIGHT_SM = 36;
export const BG_GRAY = "#fafafa";

export const BLACK_BUTTON_COLOR = "#181818";
export const BLACK_BUTTON_HOVER_COLOR = "#474747";
export const BLACK_BUTTON_ACTIVE_COLOR = "#000000";
export const PADDING_HORIZONTAL_BUTTON = 30;
export const BORDER_RADIUS_BUTTON = 40;
