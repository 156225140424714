import { ThemeConfig } from "antd";
import {
  AVATAR_CONTAINER_SIZE,
  BG_GRAY,
  BLACK_BUTTON_ACTIVE_COLOR,
  BLACK_BUTTON_COLOR,
  BLACK_BUTTON_HOVER_COLOR,
  BORDER_RADIUS,
  BORDER_RADIUS_BUTTON,
  CONTROL_HEIGHT,
  CONTROL_HEIGHT_SM,
  FONT_FAMILY,
  FONT_FAMILY_CODE,
  LIGHTER_PRIMARY,
  LIGHT_GRAY,
  LIGHT_PRIMARY,
  LINE_HEIGHT,
  PADDING_HORIZONTAL_BUTTON,
  PRIMARY,
  TEXT_GRAY,
  TEXT_SUCCESS,
  WHITE,
} from "constants/ThemeConstant";

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: PRIMARY,
    fontFamily: FONT_FAMILY,
    fontFamilyCode: FONT_FAMILY_CODE,
    colorText: "rgb(30,30,30)",
    colorTextBase: "rgb(30,30,30)",
    colorLinkHover: PRIMARY, // ex: backIcon for pageHeader component
  },
  components: {
    Alert: {
      colorInfoBg: LIGHTER_PRIMARY,
    },
    Avatar: {
      containerSize: AVATAR_CONTAINER_SIZE,
    },
    Button: {
      // override style for default 'middle' size
      controlHeight: CONTROL_HEIGHT,
      controlHeightSM: CONTROL_HEIGHT_SM,
      colorLink: PRIMARY,
      colorLinkActive: LIGHTER_PRIMARY,
      colorLinkHover: LIGHT_PRIMARY,
      // when hovering over button type="text"
      colorBgTextHover: LIGHTER_PRIMARY,
      //overrid primary button colors
      colorPrimary: BLACK_BUTTON_COLOR,
      colorPrimaryHover: BLACK_BUTTON_HOVER_COLOR,
      colorPrimaryActive: BLACK_BUTTON_ACTIVE_COLOR,
      paddingContentHorizontal: PADDING_HORIZONTAL_BUTTON,
      borderRadius: BORDER_RADIUS_BUTTON,
    },
    Input: {
      lineHeight: LINE_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
    },
    InputNumber: {
      // override style for default 'middle' size
      lineHeight: LINE_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
    },
    Layout: {
      colorBgLayout: BG_GRAY,
    },
    Menu: {
      subMenuItemBg: WHITE,
    },
    Progress: {
      //override style for default color
      colorInfo: PRIMARY,
    },
    Radio: {
      buttonSolidCheckedBg: "white",
      buttonSolidCheckedColor: "black",
      buttonSolidCheckedHoverBg: LIGHTER_PRIMARY,
      buttonColor: "black",
    },
    Select: {
      // override style for default 'middle' size
      controlHeight: CONTROL_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
    },
    Steps: {
      colorPrimary: TEXT_SUCCESS,
    },
    Slider: {
      handleSize: 18,
      handleSizeHover: 19,
      controlSize: 18,
      handleLineWidthHover: 2,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      borderRadiusSM: 20,
    },
  },
};
