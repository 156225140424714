import { ProductVariant, Region, User } from "@medusajs/medusa";
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { notification } from "antd";
import { useProductCertificateItems } from "hooks/cart-hook";
import {
  useGoodiesLineItem,
  usePackagingLogoLineItem,
  useTYCLineItem,
} from "hooks/product-hooks";
import {
  useAdminStore,
  useCart,
  useCreateLineItem,
  useDeleteLineItem,
  useMedusa,
  useUpdateLineItem,
} from "medusa-react";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { REGION_KEY, getCartKey, setCartKey } from "redux/constants/Auth";
import CartService from "services/CartService";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "./auth-context";
import { useAccount } from "./account-context";

interface VariantInfoProps {
  variantId: string;
  quantity: number;
  metadata?: any;
}

interface LineInfoProps {
  lineId: string;
  quantity: number;
  metadata?: any;
}

interface StoreContextInterface {
  countryCode: string | undefined;
  addToCartLoading: string;
  deleteItemLoading: string;
  updateItemLoading: string;
  updateNoteLoading: boolean;
  resetCartLoading: boolean;
  updateCustomizationOptionsLoading: boolean;
  ensureCart: (storeId?: string) => Promise<void>;
  getCartLoading: boolean;
  setRegion: (regionId: string, countryCode: string) => void;
  addItem: (item: VariantInfoProps, path?: string) => Promise<void>;
  updateItem: (item: LineInfoProps) => Promise<void>;
  deleteItem: (lineId: string) => Promise<void>;
  resetCart: () => void;
  addAndDeleteItem: (itemToAdd: VariantInfoProps, itemToDelete: string) => void;
  updateCustomizationOptions: (
    packagingLogo: { variantId: string | undefined },
    thankYouCard: { variantId: string | null | undefined },
    goodies: boolean
  ) => Promise<void>;
  updatePackagingLogo: (variantId: string | undefined) => void;
  updateTYC: (variantId: string | undefined) => void;
  updateNote: (note: string) => Promise<void>;
  addItems: (items: VariantInfoProps[]) => void;
  useAddAutoGeneratedCoaToCart: () => UseMutationResult<
    any,
    any,
    {
      variant: ProductVariant | PricedVariant;
      certificateVariantId: string;
      quantity?: number | undefined;
    },
    unknown
  >;
  useRemoveAutoGeneratedCoaFromCart: (variant: ProductVariant) => any;
  cleanDeletedVariant: () => void;
  resetAndRecreateCartWithExistingItems: () => void;
}

const StoreContext = React.createContext<StoreContextInterface | null>(null);

export const useStore = () => {
  const context = React.useContext(StoreContext);
  if (context === null) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};

interface StoreProps {
  children: React.ReactNode;
}

const IS_SERVER = typeof window === "undefined";

export const StoreProvider = ({ children }: StoreProps) => {
  const { cart, setCart, createCart, updateCart } = useCart();
  const { token } = useAuth();

  const { store } = useAdminStore({
    enabled: !!token,
  });

  const default_studio_note = useMemo(() => {
    return store?.studio_note;
  }, [store]);

  const { client: medusaClient } = useMedusa();

  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  //const { timedOpen } = useCartDropdown()
  const idempotencyKey = uuidv4();
  const addLineItem = useCreateLineItem(cart?.id!, {
    meta: { "Idempotency-Key": idempotencyKey },
  });
  const removeLineItem = useDeleteLineItem(cart?.id!);
  const adjustLineItem = useUpdateLineItem(cart?.id!);
  const [updateNoteLoading, setUpdateNoteLoading] = useState<boolean>(false);
  const [resetCartLoading, setResetCartLoading] = useState<boolean>(false);
  const [getCartLoading, setGetCartLoading] = useState<boolean>(false);
  const [addToCartLoading, setAddToCartLoading] = useState<string>("");
  const [updateItemLoading, setUpdateItemLoading] = useState<string>("");
  const [deleteItemLoading, setDeleteItemLoading] = useState<string>("");
  const [
    updateCustomizationOptionsLoading,
    setUpdateCustomizationOptionsLoading,
  ] = useState<boolean>(false);

  const navigate = useNavigate();

  const { user } = useAccount();

  const packagingLogoLineItem = usePackagingLogoLineItem(true);
  const goodiesLineItem = useGoodiesLineItem();
  const TYCLineItem = useTYCLineItem(true);
  const REGION_USER_KEY = useMemo(() => `${REGION_KEY}_${user?.id}`, [user]);

  const storeRegion = (regionId: string, countryCode: string) => {
    if (!IS_SERVER) {
      localStorage.setItem(
        REGION_USER_KEY,
        JSON.stringify({ regionId, countryCode })
      );

      setCountryCode(countryCode);
    }
  };

  useEffect(() => {
    if (!IS_SERVER) {
      const storedRegion = localStorage.getItem(REGION_USER_KEY);
      if (storedRegion) {
        const { countryCode } = JSON.parse(storedRegion);
        setCountryCode(countryCode);
      }
    }
  }, []);

  const cleanDeletedVariant = () => {
    //Delete item from the cart that have variant_id null
    if (cart) {
      cart.items.forEach(async (item) => {
        if (!item.variant_id) {
          await removeLineItem.mutateAsync({ lineId: item.id });
        }
      });
    }
  };

  const getRegion = () => {
    if (!IS_SERVER) {
      const region = localStorage.getItem(REGION_USER_KEY);
      if (region) {
        return JSON.parse(region) as { regionId: string; countryCode: string };
      }
    }
    return null;
  };

  const setRegion = async (regionId: string, countryCode: string) => {
    if (cart?.id) {
      await updateCart.mutateAsync(
        {
          region_id: regionId,
        },
        {
          onSuccess: ({ cart }) => {
            setCart(cart);
            storeCart(cart.id);
            storeRegion(regionId, countryCode);
          },
          onError: (error) => {
            if (process.env.NODE_ENV === "development") {
              console.error(error);
            }
          },
        }
      );
    }
  };

  const ensureRegion = (
    region: Region,
    countryCode?: string | null,
    preventCartUpdate?: boolean
  ) => {
    if (!IS_SERVER) {
      const { regionId, countryCode: defaultCountryCode } = getRegion() || {
        regionId: region.id,
        countryCode: region.countries[0].iso_2,
      };
      const finalCountryCode = countryCode || defaultCountryCode;
      console.log();
      if (regionId !== region.id && !preventCartUpdate) {
        setRegion(region.id, finalCountryCode);
      }

      storeRegion(region.id, finalCountryCode);
      setCountryCode(finalCountryCode);
    }
  };

  const storeCart = (id: string) => {
    if (!IS_SERVER) {
      localStorage.setItem(getCartKey(), id);
    }
  };

  const getCart = () => {
    if (!IS_SERVER) {
      return localStorage.getItem(getCartKey());
    }
    return null;
  };

  const deleteCart = () => {
    if (!IS_SERVER) {
      localStorage.removeItem(getCartKey());
    }
  };

  const deleteRegion = () => {
    if (!IS_SERVER) {
      localStorage.removeItem(REGION_USER_KEY);
    }
  };

  const createNewCart = async (regionId?: string, storeId?: string) => {
    try {
      const { cart } = await createCart.mutateAsync({
        store_id: storeId || store?.id,
        region_id: regionId,
      });
      let newCart = cart;
      //update cart metadata
      if (!!default_studio_note && !!cart?.id) {
        const res: any = await CartService.updateMetadata(cart?.id, {
          metadata: { workshop_note: default_studio_note },
        });
        newCart = res.cart;
      }

      setCart(newCart);
      storeCart(newCart.id);
      ensureRegion(newCart.region, newCart.shipping_address?.country_code);
      setResetCartLoading(false);
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
    }
  };

  const resetCart = async () => {
    setResetCartLoading(true);
    const savedRegionId = cart?.region_id;
    deleteCart();
    try {
      const { cart } = await createCart.mutateAsync({
        store_id: store?.id,
        region_id: savedRegionId,
      });
      let newCart = cart;
      //update cart metadata
      if (!!default_studio_note && !!cart?.id) {
        const res: any = await CartService.updateMetadata(cart?.id, {
          metadata: { workshop_note: default_studio_note },
        });
        newCart = res.cart;
      }

      setCart(newCart);
      storeCart(newCart.id);
      ensureRegion(
        newCart.region,
        newCart.shipping_address?.country_code,
        true
      );

      setResetCartLoading(false);
    } catch (error) {
      setResetCartLoading(false);
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
    }
  };

  const ensureCart = async (storeId?: string) => {
    setGetCartLoading(true);
    const cartId = getCart();
    const region = getRegion();
    try {
      if (cartId) {
        const { cart } = await medusaClient.carts.retrieve(cartId);

        if (
          !cart ||
          cart.completed_at ||
          (store?.default_currency_code &&
            cart.region?.currency_code !== store.default_currency_code)
        ) {
          deleteCart();
          deleteRegion();
          await createNewCart(undefined, storeId);
          setGetCartLoading(false);
          return;
        }

        setCart(cart);
        ensureRegion(cart.region);
      } else {
        await createNewCart(region?.regionId, storeId);
      }

      setGetCartLoading(false);

      cleanDeletedVariant();
    } catch (e) {
      setGetCartLoading(false);
    }
  };

  useEffect(() => {
    cleanDeletedVariant();

    // the 'if' prevents creating a cart when not logged in
    if (!user?.id) return;

    setCartKey(user.id);
    if (!IS_SERVER && !cart?.id && !!store?.id) {
      ensureCart();
    }
  }, [user, store]);

  const addItem = async (
    {
      variantId,
      quantity,
      metadata,
    }: {
      variantId: string;
      quantity: number;
      metadata?: any;
    },
    path?: string
  ) => {
    setAddToCartLoading(variantId);
    await ensureCart();
    await addLineItem.mutateAsync(
      {
        variant_id: variantId,
        quantity: quantity,
        metadata,
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart);
          storeCart(cart.id);
          setAddToCartLoading("");
          if (path) navigate(path);
        },
        onError: (error) => {
          setAddToCartLoading("");
          //handleError(error)
        },
      }
    );
  };

  const deleteItem = async (lineId: string) => {
    setDeleteItemLoading(lineId);
    await removeLineItem.mutateAsync(
      {
        lineId,
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart);
          storeCart(cart.id);
          setDeleteItemLoading("");
        },
        onError: (error) => {
          setDeleteItemLoading("");
          //handleError(error)
        },
      }
    );
  };

  const updateItem = async ({
    lineId,
    quantity,
  }: {
    lineId: string;
    quantity: number;
  }) => {
    setUpdateItemLoading(lineId);
    await adjustLineItem.mutateAsync(
      {
        lineId,
        quantity,
      },
      {
        onSuccess: ({ cart }) => {
          setUpdateItemLoading("");
          setCart(cart);
          storeCart(cart.id);
        },
        onError: (error) => {
          setUpdateItemLoading("");
          //handleError(error)
        },
      }
    );
  };

  const addAndDeleteItem = (
    {
      variantId,
      quantity,
    }: {
      variantId: string;
      quantity: number;
    },
    lineId: string
  ) => {
    setAddToCartLoading(variantId);
    addLineItem.mutate(
      {
        variant_id: variantId,
        quantity: quantity,
      },
      {
        onSuccess: ({ cart }) => {
          setDeleteItemLoading(lineId);
          removeLineItem.mutate(
            { lineId: lineId },
            {
              onSuccess: ({ cart }) => {
                setCart(cart);
                storeCart(cart.id);
                setAddToCartLoading("");
                setDeleteItemLoading("");
              },
              onError: (error) => {
                setAddToCartLoading("");
                setDeleteItemLoading("");
                //handleError(error)
              },
            }
          );
        },
        onError: (error) => {
          setAddToCartLoading("");
          //handleError(error)
        },
      }
    );
  };

  const updateCustomizationOptions = async (
    packagingLogo: { variantId: string | undefined },
    thankYouCard: { variantId: string | null | undefined },
    goodies: boolean
  ) => {
    setUpdateCustomizationOptionsLoading(true);
    let newCart = cart;
    if (thankYouCard.variantId) {
      if (TYCLineItem?.variant_id !== thankYouCard.variantId) {
        try {
          if (TYCLineItem) {
            setDeleteItemLoading(TYCLineItem?.id || "");
            newCart = (
              await removeLineItem.mutateAsync({
                lineId: TYCLineItem?.id || "",
              })
            ).cart;
            setDeleteItemLoading("");
          }
          newCart = (
            await addLineItem.mutateAsync({
              variant_id: thankYouCard.variantId || "",
              quantity: 1,
            })
          ).cart;
        } catch (e) {
          console.log(e);
          setDeleteItemLoading("");
        }
      }
    } else {
      if (TYCLineItem) {
        newCart = (
          await removeLineItem.mutateAsync({ lineId: TYCLineItem?.id || "" })
        ).cart;
      }
    }
    if (packagingLogo.variantId && !packagingLogoLineItem) {
      try {
        newCart = (
          await addLineItem.mutateAsync({
            variant_id: packagingLogo.variantId || "",
            quantity: 1,
          })
        ).cart;
      } catch (e) {
        console.log(e);
      }
    }

    if (packagingLogoLineItem && !packagingLogo.variantId) {
      try {
        setDeleteItemLoading(packagingLogoLineItem.id);
        newCart = (
          await removeLineItem.mutateAsync({ lineId: packagingLogoLineItem.id })
        ).cart;
        setDeleteItemLoading("");
      } catch (e) {
        console.log(e);
        setDeleteItemLoading("");
      }
    }

    if (goodies && !goodiesLineItem) {
      try {
        newCart = (
          await addLineItem.mutateAsync({
            variant_id: process.env.GOODIES_VARIANT_ID || "",
            quantity: 1,
          })
        ).cart;
      } catch (e) {
        console.log(e);
      }
    } else if (!goodies && goodiesLineItem) {
      try {
        setDeleteItemLoading(goodiesLineItem.id);
        newCart = (
          await removeLineItem.mutateAsync({ lineId: goodiesLineItem.id })
        ).cart;
        setDeleteItemLoading("");
      } catch (e) {
        console.log(e);
        setDeleteItemLoading("");
      }
    }

    if (newCart) {
      setCart(newCart);
      storeCart(newCart.id);
    }
    setUpdateCustomizationOptionsLoading(false);
  };

  const updatePackagingLogo = async (variantId: string | undefined) => {
    setUpdateCustomizationOptionsLoading(true);
    let newCart = cart;
    if (variantId && !packagingLogoLineItem) {
      try {
        newCart = (
          await addLineItem.mutateAsync({
            variant_id: variantId || "",
            quantity: 1,
          })
        ).cart;
      } catch (e) {
        console.log(e);
      }
    }

    if (packagingLogoLineItem && !variantId) {
      try {
        setDeleteItemLoading(packagingLogoLineItem.id);
        newCart = (
          await removeLineItem.mutateAsync({ lineId: packagingLogoLineItem.id })
        ).cart;
        setDeleteItemLoading("");
      } catch (e) {
        console.log(e);
        setDeleteItemLoading("");
      }
    }

    if (newCart) {
      setCart(newCart);
      storeCart(newCart.id);
    }
    setUpdateCustomizationOptionsLoading(false);
  };

  const updateTYC = async (variantId: string | undefined) => {
    setUpdateCustomizationOptionsLoading(true);
    let newCart = cart;
    if (variantId) {
      if (TYCLineItem?.variant_id !== variantId) {
        try {
          if (TYCLineItem) {
            setDeleteItemLoading(TYCLineItem?.id || "");
            newCart = (
              await removeLineItem.mutateAsync({
                lineId: TYCLineItem?.id || "",
              })
            ).cart;
            setDeleteItemLoading("");
          }
          newCart = (
            await addLineItem.mutateAsync({
              variant_id: variantId || "",
              quantity: 1,
            })
          ).cart;
        } catch (e) {
          console.log(e);
          setDeleteItemLoading("");
        }
      }
    } else {
      if (TYCLineItem) {
        newCart = (
          await removeLineItem.mutateAsync({ lineId: TYCLineItem?.id || "" })
        ).cart;
      }
    }

    if (newCart) {
      setCart(newCart);
      storeCart(newCart.id);
    }
    setUpdateCustomizationOptionsLoading(false);
  };

  const handlePostNote = async (note: string) => {
    const resp: any = await CartService.updateMetadata(cart?.id, {
      metadata: { workshop_note: note },
    });
    return resp;
  };
  const useUpdateNote = useMutation(["update-note"], handlePostNote);

  const updateNote = async (note: string) => {
    setUpdateNoteLoading(true);
    await useUpdateNote.mutateAsync(note, {
      onSuccess: ({ cart }) => {
        setCart(cart);
        storeCart(cart.id);
        setUpdateNoteLoading(false);
        //const queryKeys = cartKeys.lists();
        //queryClient.invalidateQueries(queryKeys);
      },
      onError: () => {
        console.log("error");
        setUpdateNoteLoading(false);
        notification.error({ message: "Issue while updating note" });
      },
    });
  };

  const addItems = async (items: VariantInfoProps[]) => {
    const result = items.map(async (item, idx) => {
      let shouldRetry = true;
      while (shouldRetry) {
        try {
          const data = await addLineItem.mutateAsync({
            variant_id: item.variantId,
            quantity: item.quantity,
            metadata: item.metadata,
          });
          shouldRetry = false;
          return data;
        } catch (e: any) {
          console.log(e);
          if (e.response.data.code === "invalid_state_error") {
            console.log("should retry");
          } else {
            shouldRetry = false;
          }
        }
      }
      return null;
    });
    return await Promise.all(result);
  };

  function useRemoveAutoGeneratedCoaFromCart(
    variant: ProductVariant | PricedVariant
  ) {
    const { productCertificates } = useProductCertificateItems(
      variant?.id as string
    );
    const sortedVariants = useMemo(
      () =>
        productCertificates.autoCertificates?.sort((a, b) => {
          const aNumber = parseInt(a.variant.title.split("/")[0]);
          const bNumber = parseInt(b.variant.title.split("/")[0]);
          return bNumber - aNumber;
        }),
      [productCertificates.autoCertificates]
    );

    return useMutation({
      mutationFn: async ({ quantity }: { quantity?: number }) => {
        if (!quantity || !sortedVariants) {
          return;
        }
        const deletePromises = [...Array(quantity).keys()].map(
          (_, idx) =>
            sortedVariants[idx] &&
            removeLineItem.mutateAsync({
              lineId: sortedVariants[idx].id,
            })
        );
        return await Promise.all(deletePromises);
      },
      onSuccess: (data: any) => {
        if (data && data.length > 0 && data[0]?.cart) {
          setCart(data[0].cart);
          storeCart(data[0].cart.id);
        }
        return data;
      },
    });
  }

  function useAddAutoGeneratedCoaToCart() {
    return useMutation<
      any,
      any,
      {
        variant: ProductVariant | PricedVariant;
        certificateVariantId: string;
        quantity?: number;
      }
    >({
      mutationFn: async ({ variant, quantity, certificateVariantId }) => {
        if (!quantity && !variant && !certificateVariantId) return;
        return await addLineItem.mutateAsync({
          variant_id: certificateVariantId,
          quantity: quantity as number,
          metadata: {
            variant_id: variant?.id as string,
          },
        });
      },
      onSuccess: ({ cart }: { cart: any }) => {
        setCart(cart);
        storeCart(cart.id);
        setAddToCartLoading("");
      },
      onError: (error) => {
        setAddToCartLoading("");
      },
    });
  }

  async function resetAndRecreateCartWithExistingItems() {
    // Ensure there's a cart to reset and recreate
    if (!cart || !cart.items.length) {
      console.log("No cart or cart is empty.");
      return;
    }

    // Save the current items and their quantities
    const savedItems = cart.items.map((item) => ({
      variantId: item.variant_id as string,
      quantity: item.quantity,
      metadata: item.metadata,
    }));
    try {
      setResetCartLoading(true);
      const savedRegionId = cart?.region_id;

      deleteCart();
      const { cart: newCart } = await createCart.mutateAsync({
        store_id: store?.id,
        region_id: savedRegionId,
      });

      // setCart(newCart);
      storeCart(newCart.id);
      ensureRegion(newCart.region, newCart.shipping_address?.country_code);

      //update cart metadata
      if (!!default_studio_note && !!newCart?.id) {
        const res: any = await CartService.updateMetadata(newCart?.id, {
          metadata: { workshop_note: default_studio_note },
        });
        // setCart(res.cart);
      }
      //add back old items
      let newAddedCart = newCart;
      for (const item of savedItems) {
        await medusaClient.carts.lineItems.create(
          newCart.id,
          {
            variant_id: item.variantId,
            quantity: item.quantity,
            metadata: item.metadata,
          },
          { "Idempotency-Key": idempotencyKey }
        );
      }
      setCart(newAddedCart);

      setResetCartLoading(false);
    } catch (e) {
      console.log(e);
      setResetCartLoading(false);
    }
  }

  // Add this function to your StoreContextInterface to make it available throughout your application

  return (
    <StoreContext.Provider
      value={{
        countryCode,
        setRegion,
        addItem,
        deleteItem,
        updateItem,
        resetCart,
        addToCartLoading,
        deleteItemLoading,
        updateNoteLoading,
        updateItemLoading,
        resetCartLoading,
        updateCustomizationOptionsLoading,
        getCartLoading,
        addAndDeleteItem,
        updateCustomizationOptions,
        updatePackagingLogo,
        updateTYC,
        updateNote,
        addItems,
        useAddAutoGeneratedCoaToCart,
        useRemoveAutoGeneratedCoaFromCart,
        cleanDeletedVariant,
        ensureCart,
        resetAndRecreateCartWithExistingItems,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
