import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-fetch-backend";

export const availableLanguages = [
  {
    value: "en",
    flag: "us",
  },
  { value: "fr", flag: "fr" },
  // { value: "es", flag: "es" },
  // { value: "de", flag: "de" },
  // { value: "it", flag: "it" },
  // { value: "pt", flag: "pt" },
  // { value: "nl", flag: "nl" },
];

export const localeBaseUrl = `/:locale(${availableLanguages
  .map(({ value }) => value)
  .join("|")})?`;

i18n.on("languageChanged", function (lng) {
  const currentPath = window.location.pathname;
  const queryParams = window.location.search;

  // Remove leading slash and split by slash to get path segments
  const pathSegments = currentPath.replace(/^\//, "").split("/");

  // Check if the first segment is a valid language code
  if (!availableLanguages.find(({ value }) => value === pathSegments[0])) {
    // If not, prepend the language code
    const newUrl = `/${lng}${currentPath}${queryParams}`;
    window.location.replace(newUrl);
  } else {
    // If the first segment is a valid language code, replace it with the new language
    if (pathSegments[0] !== lng) {
      pathSegments[0] = lng;
      const newUrl = `/${pathSegments.join("/")}${queryParams}`;
      window.location.replace(newUrl);
    }
  }
});

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    backend: {
      /* translation file path */
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["path", "localStorage", "navigator"],
      lookupFromPathIndex: 0,
    },
    supportedLngs: availableLanguages.map((lang) => lang.value),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
