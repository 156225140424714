import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import type { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateRangePreset {
  label: string;
  value: [dayjs.Dayjs, dayjs.Dayjs];
}

export const getDateLocale = (locale) => {
  return {
    fr: "fr-FR",
    en: "en-US",
  }[locale];
};

export function useGenerateRangePresets() {
  const { t } = useTranslation("home/dashboard/analytics");

  return generateRangePresets(t);
}

export const generateRangePresets = (t) => {
  return (startDate: Dayjs, endDate: Dayjs): DateRangePreset[] => {
    const presets: DateRangePreset[] = [];
    function addPreset(label: string, days: number) {
      const startPeriod = endDate.subtract(days, "day");
      if (startPeriod.isAfter(startDate) || startPeriod.isSame(startDate)) {
        presets.push({
          label: label,
          value: [startPeriod, endDate],
        });
      }
    }

    addPreset(t("select.7_days"), 7);
    addPreset(t("select.14_days"), 14);
    addPreset(t("select.30_days"), 30);
    addPreset(t("select.90_days"), 90);

    presets.push({
      label: t("select.all_time"),
      value: [startDate, endDate],
    });

    return presets;
  };
};
